import { Dot, Icon, Table, TableSortButton, Td, Th, Tr } from '@frontend/ui';
import {
  _delete,
  contactPage,
  contentCopy,
  download,
  edit,
  upload,
} from '@frontend/ui/icons';
import { ThemeColor } from '@frontend/ui/theme';
import {
  ProposalStatus,
  smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge_node_Proposal as Proposal,
  SortingFields,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/sme/company';
import { TableSort } from 'app/utils/use-table-sort';
import { DefaultA } from 'components/DefaultA';
import { FormattedDate, FormattedMessage } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import {
  smeCompanyProposalsMessages,
  smeProposalStatusMessages,
} from 'features/sme/messages/sme';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import { SortableColumns } from '..';
import { getEditProposalNameLink } from '../edit/name';
import { getExecuteProposalLink } from '../execute';

const STATUS_COLORS: Record<ProposalStatus, ThemeColor> = {
  STARTED: 'lightGray',
  GENERATED: 'orange',
  IN_PRODUCTION: 'green',
};

interface Props {
  handleDeleteProposal: (proposal: Proposal) => void;
  handleDuplicateProposal: (proposalId: string) => void;
  navigation: React.ReactNode;
  proposals: Proposal[];
  sort: TableSort<SortableColumns>;
}

export const ProposalsTable: React.FC<Props> = ({
  handleDeleteProposal,
  handleDuplicateProposal,
  navigation,
  proposals,
  sort,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { companyId } = useParams<MatchParams>();
  return (
    <Table navigation={navigation}>
      <>
        <colgroup>
          <col style={{ width: 'auto' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '20%' }} />
        </colgroup>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...smeCompanyProposalsMessages.proposalName} />
            </Th>
            <Th>
              <FormattedMessage {...smeCompanyProposalsMessages.createdBy} />
            </Th>
            <Th>
              <TableSortButton
                onClick={sort.handleSort}
                order={
                  sort.orderBy === SortingFields.STATUS ? sort.order : undefined
                }
                value={SortingFields.STATUS}
              >
                <FormattedMessage {...commonMessages.status} />
              </TableSortButton>
            </Th>
            <Th type="number">
              <TableSortButton
                onClick={sort.handleSort}
                order={
                  sort.orderBy === SortingFields.UPDATED_AT
                    ? sort.order
                    : undefined
                }
                value={SortingFields.UPDATED_AT}
              >
                <FormattedMessage
                  {...smeCompanyProposalsMessages.lastEditedAt}
                />
              </TableSortButton>
            </Th>
            <Th>
              <FormattedMessage {...smeCompanyProposalsMessages.lastEditedBy} />
            </Th>
            <Th />
          </Tr>
        </thead>
        <tbody>
          {proposals.map(proposal => {
            const proposalUrl = `/sme/${companyId}/proposals/${proposal.id}`;
            const { documents } = proposal;

            return (
              <Tr
                key={proposal.id}
                inlineMenu={
                  <KebabMenu fixed inClickableArea>
                    {proposal.status === ProposalStatus.STARTED && (
                      <MenuItem
                        title={
                          <FormattedMessage
                            {...smeCompanyProposalsMessages.editOffer}
                          />
                        }
                        onClick={() => history.push(proposalUrl)}
                        icon={<Icon icon={edit} decorative />}
                      />
                    )}
                    <MenuItem
                      title={
                        <FormattedMessage
                          {...smeCompanyProposalsMessages.duplicateProposal}
                        />
                      }
                      onClick={() => handleDuplicateProposal(proposal.id)}
                      icon={<Icon icon={contentCopy} decorative />}
                    />
                    {proposal.status === ProposalStatus.STARTED && (
                      <>
                        <MenuItem
                          title={
                            <FormattedMessage
                              {...smeCompanyProposalsMessages.editProposalName}
                            />
                          }
                          link={getEditProposalNameLink({
                            location,
                            proposalId: proposal.id,
                          })}
                          icon={<Icon icon={contactPage} decorative />}
                        />
                        <MenuItem
                          title={
                            <FormattedMessage
                              {...smeCompanyProposalsMessages.deleteProposal}
                            />
                          }
                          onClick={() => handleDeleteProposal(proposal)}
                          icon={<Icon icon={_delete} decorative />}
                        />
                      </>
                    )}
                    {proposal.status === ProposalStatus.GENERATED && (
                      <MenuItem
                        title={
                          <FormattedMessage
                            {...smeCompanyProposalsMessages.sendToProduction}
                          />
                        }
                        link={getExecuteProposalLink({
                          location,
                          proposalId: proposal.id,
                        })}
                        icon={<Icon icon={upload} decorative />}
                      />
                    )}
                    {documents?.offer && (
                      <DefaultA
                        download
                        href={documents.offer.url}
                        target="_blank"
                      >
                        <MenuItem
                          title={
                            <FormattedMessage
                              {...smeCompanyProposalsMessages.downloadProposal}
                            />
                          }
                          icon={<Icon icon={download} decorative />}
                        />
                      </DefaultA>
                    )}
                    {documents?.otherDocuments?.map(attachment => (
                      <DefaultA download href={attachment.url} target="_blank">
                        <MenuItem
                          title={
                            <FormattedMessage
                              {...smeCompanyProposalsMessages.downloadAttachment}
                              values={{ attachmentName: attachment.name }}
                            />
                          }
                          icon={<Icon icon={download} decorative />}
                        />
                      </DefaultA>
                    ))}
                  </KebabMenu>
                }
                linked
                onClick={() => history.push(proposalUrl)}
              >
                <Td>{proposal.name}</Td>
                <Td>{`${proposal.createdBy.firstName} ${proposal.createdBy.lastName}`}</Td>
                <Td>
                  <Dot
                    colorValue={STATUS_COLORS[proposal.status]}
                    size={0.75}
                    label={
                      <FormattedMessage
                        select={proposal.status}
                        messages={smeProposalStatusMessages}
                      />
                    }
                  />
                </Td>
                <Td align="left" type="number">
                  <FormattedDate
                    value={proposal.updatedAt}
                    timeStyle="short"
                    dateStyle="short"
                  />
                </Td>
                <Td>{`${proposal.lastEditedBy.firstName} ${proposal.lastEditedBy.lastName}`}</Td>
              </Tr>
            );
          })}
        </tbody>
      </>
    </Table>
  );
};
